@import "../../scss/color-palette";
@import "../../scss/mixins";

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  gap: 1rem;
  &_nuevo {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: #fb434a;
    text-align: center;
    color: #fff;
    top: -50px;
    right: -50px;
    line-height: 180px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    text-transform: uppercase;
  }
  &_descount {
    position: absolute;
    background-color: lighten($promocolor, 9);
    text-align: center;
    top: 15%;
    right: 0;
    padding-left: 0.5rem;
    border-radius: 6px 0 0 6px;
  }
  &_image_container {
    height: 345px;
    @media screen and (max-width: 414px) {
      height: 420px;
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  &_body_card {
    display: flex;
    flex-direction: column;
    padding: 0.7rem;

    .item_price {
      background-color: $accent-primary-light-3;
      min-height: 73px;
      font-weight: 400 !important;

      &_off {
        font-size: 1.5rem !important;
        text-align: right;
      }
      &_regular {
        @extend .item_price_off;
      }
    }
    .item_title {
      margin-bottom: 0;
      font-size: 18px !important;
      font-weight: 400 !important;
    }
    .item_short_description_container {
      padding: 0.7rem;
      background-color: $accent-gray-light-3;
      border-radius: 3px;

      .item_short_description_text {
        font-size: 12px !important;
        font-weight: 400 !important;
      }
    }
    .badge {
      background-color: $accent-primary-light-1;
      font-size: 10px !important;
      margin: 0 !important;
    }
  }
  .item_button_container {
    padding: 3px;
    margin-top: auto;
    .item_button {
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }
  }
}
.product {
  margin-bottom: 2rem;
}
.product {
  margin-bottom: 2rem;
}
.item_descount {
  margin-top: 12em;
  padding-right: 1em;
  background-color: #0abde3;
}
.spans_smalls {
  font-size: 13px;
  margin-right: 8px;
}

.spansContainer {
  display: flex;
  margin-right: 1;
}
