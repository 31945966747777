@import "../../scss/color-palette";
@import "../../scss/mixins";

.logo {
    max-width: 150px;
}

.navbar {
    color: lightblue;
    @include theme-aware("border-bottom-color", "accent-primary-dark-1");
    > .container {
        flex-wrap: nowrap;
    }
    .navbar_brand {
        width: 70%;
        @media (min-width: 767px) {
            width: 30%;
        }
    }
    .navbar_collapse {
        flex-grow: 0;
        @media (max-width: 991px) {
            margin-bottom: 1rem;
            position: absolute;
            background: #fff;
            z-index: 1;
            right: 0.5rem;
            margin-top: 39rem;
            width: 20rem;
            box-shadow: -1px 5px 5px 2px #44444424;
            padding-bottom: 0.5rem;
        }
        @media (max-width: 414px) {
            width: 95%;
        }
    }
    .nav_item {
        @media (max-width: 991px) {
            padding: 0.7rem 0;
        }
        @media screen and (max-width: 763px) {
            margin-right: 0 !important;
        }
    }
    .link_menu {
        @media (max-width: 991px) {
            width: 100%;
            padding: 0.7rem 0;
            &:hover {
                background-color: $accent-primary-light-1;
            }
        }
    }

    .social_icons {
        margin: 0 0.7rem;
    }
    .button_cart {
        &_ul {
            margin-left: 0;
            margin-bottom: 0;
            @media screen and (max-width: 763px) {
                padding: 0;
                margin: 0.5rem 1.5rem;
            }
        }
        &_li {
            list-style: none;
        }
        &_link {
            border-color: $accent-primary-light-1 !important;
        }
    }
    .navbar-nav {
        .nav-link {
            color: $accent-primary-dark-1;
        }
    }
}
