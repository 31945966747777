@import "../../scss/color-palette";
@import "../../scss/mixins";

.welcome {
    margin-top: 55px;
    margin-bottom: 55px;
}

.text_justify {
    text-align: justify !important;
    text-justify: inter-word !important;
}
