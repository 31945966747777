@import '../../scss/color-palette';

#payment-buttons,
#payment-shipping {
    label.btn {
        &.active {
            background-color: $accent-primary-dark-2;
            color:white;
            i.fa {
                color:#fff !important;
            }
        }
    }
}
.table-innshop {
    border-top: 2px solid $accent-primary-dark-3;
    .table-cells-cart {
        p {
            margin-bottom: 0;
        }
    }
}

#card-form {
    .form-group {
        label {
            font-size: 14px;
        }
    }
}
.total-price {
    font-size: 1.7rem;
    font-weight: 300;
    padding-top: unset;
}

.form-group{
    label{
        margin-bottom: .5rem;
        font-weight: 500;
    }
}
figure.PaymentLogoGrid {
    svg {
        margin-left: 1rem;
    }
}
.__PrivateStripeElement-input {
    position: absolute !important;
    width: 100% !important;
    font-size: 16px !important;
    height: 2rem !important;
    background: white !important;
    border: 1px solid #ced4da !important;
    border-radius: 2.5rem !important;
    
}
#card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }