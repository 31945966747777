@import "../../../scss/color-palette";

.cardinfo {
    padding: 1.875rem;
    border-radius: 8px;
    li {
        color: $accent-primary-dark-1;
    }
    &-blue {
        background-color: $accent-primary-light-1;
    }
    &-lightblue {
        background-color: $accent-primary-light-3;
    }
    &-peach {
        background-color: $accent-secondary-light-3;
    }
    &-lightpeach {
        background-color: $accent-secondary-light-4;
    }
    &-brown {
        background-color: $accent-secondary-dark-3;
    }
    &-lightbrown {
        background-color: $accent-secondary-dark-1;
    }
    &-ultralightbrown {
        background-color: $accent-secondary-light-1;
    }
    &-megaultralightbrown {
        background-color: $accent-secondary-light-2;
    }
    .info_bar_item {
        a.body-small {
            margin-bottom: 0;
        }
    }
}
