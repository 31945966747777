@import "../../scss/color-palette";
@import "../../scss/mixins";

.welcome {
    margin-top: 55px;
    margin-bottom: 55px;
}

.products_cards {
    &_item {
        width: 100%;
    }
}
// .info_bar {
//     position: relative;
//     margin-top: 3rem;

//     &_container {
//         border-radius: 5px;
//         background:#FDF7F4;

//         h5 {
//             color: $accent-primary-dark-1;
//             margin-bottom: 0;
//         }
//         p {
//            color: $accent-primary;
//         }
//     }

// }
