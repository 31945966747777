@import "node_modules/bootstrap/scss/bootstrap";


$primary: $accent-primary;
$secondary: $accent-secondary-dark-1;
$btn-link-color: white;

$custom-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $promocolor
  );

$theme-colors: map-merge($theme-colors, $custom-colors);

//Overide bootstrap styles
.table {margin-bottom:0}
.table-sm > :not(caption) > * > * {
  padding:0.25rem 0.75rem
}
.table-bordered > :not(caption) > * > * {
  border-width:0;
}
.modal-backdrop {
  background-color: rgba(0,0,0,0.3);
}
.nav-link {
  color:$accent-primary-dark-2;
}
// text primary color
.text-primary {
  color:$accent-primary !important;
}
.text-primary-dark-1 {
  color:$accent-primary-dark-1 !important;
}
.text-primary-dark-2 {
  color:$accent-primary-dark-2 !important;
}
// text primary color
.text-secondary {
  color:$secondary !important;
}
// application BS5 palette color
// https://getbootstrap.com/docs/5.1/customize/color/
.text-color-sucess {
  color: $teal-500 !important
}
.alert-success {
  color: $teal-700;
  background-color:$teal-100;
}
.text-success {
  color: $color-special-1 !important;
}
