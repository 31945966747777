@import "../../scss/color-palette";

.wizard {
  .container {
    /*padding-left: 0;
        padding-right: 0;*/
  }
  .figure {
    margin-bottom: 1rem;
    &.item-container {
      position: relative;

      i.fas {
        position: absolute;
        right: 0;
        top: -3px;
      }

      img.img-thumbnail {
        border: none;
        width: 110px;
        height: 110px;
        object-fit: contain;
      }
      figcaption.figure-caption {
        text-align: center;
      }
    }
  }
  .material-container,
  .tela-container {
    text-align: center;
    h3 {
      @media screen and (min-width: 768px) {
        margin-top: 93px;
      }
      @media screen and (max-width: 414px) {
        margin-top: 3rem;
      }
    }
    @media screen and (max-width: 820px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .tela-container {
    @media screen and (max-width: 767px) {
      margin-bottom: 4rem;
    }
  }
  .material {
    width: 400px;
    height: 250px;
    object-fit: cover;
    padding: 0.25rem;
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 5%);
    border: 2px solid #b7dae9 !important;
  }
  .selected-style {
    height: 220px;
    object-fit: contain;
  }
  .label {
    color: $accent-primary-dark-1;
  }
  .input {
    min-height: 42px !important;
    border-color: $accent-primary-light-1 !important;
    background-color: $accent-primary-light-3 !important;
  }
  .pricing-details {
    p {
      line-height: 0;
    }
  }
  .details-text {
    font-size: 1rem;
    color: $accent-primary-light-0;
    text-transform: capitalize;
    span {
      font-weight: 500;
    }
  }
  .descripcion-prenda {
    background-color: #e0f0f6;
    padding: 1rem;
    border-radius: 5px 5px 0 0;
    height: 147px;
    overflow: hidden;
    position: relative;
    p {
      font-size: 0.85rem !important;
    }
    &.expand {
      height: auto;
    }
  }
  .more {
    bottom: 0;
    background: #e0f0f6;
    width: 100%;
    text-align: right;
    padding: 0.5rem 16px 0.5rem 0;
    font-size: 1rem;
    border-radius: 0 0 5px 5px;
    &:hover {
      cursor: pointer;
    }
    .hide {
      display: none;
    }
  }
  .cloth-container {
    padding: 3px;
    text-align: center;

    .figure-caption {
      border-radius: 5px;
      padding: 5px;

      color: $accent-gray-dark-1;
    }

    .title-text-cloth {
      font-size: 15px;
    }

    .detail-text-cloth {
      font-size: 13px;
      color: $accent-gray-dark-3 !important;
    }
  }

  @media screen and (max-width: 414px) {
    .ant-sig {
      background: #e0f0f6;
      justify-content: center !important;
      width: 93%;
      position: fixed;
      bottom: 0;
      padding: 1rem;
      button {
        font-size: 15px;
      }
    }
  }
  @media screen and (max-width: 884px) and (orientation: landscape) {
    .ant-sig {
      background: #e0f0f6;
      justify-content: center !important;
      width: 93%;
      position: fixed;
      bottom: 0;
      padding: 1rem;
      button {
        font-size: 15px;
      }
    }
  }
}
