@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,300&display=swap");

//TIPOGRAFIA
/* FONTS */
/* latin-ext */
$font-family: "Lato", sans-serif;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-regular: 400;
$font-weight-light: 300;

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem !important;
}

@mixin Heading($fontSizeDesktop, $lineHeightDesktop, $fontSizeMobile, $lineHeightMobile, $fontweightDesktop: 400, $fontweightMobile: 400) {
    font-size: $fontSizeDesktop + px !important;
    line-height: $lineHeightDesktop + px !important;
    font-weight: $fontweightDesktop !important;
    @media (max-width: 768px) {
        font-size: $fontSizeMobile + px !important;
        line-height: $lineHeightMobile + px !important;
        font-weight: $fontweightMobile !important;
    }
}

h1.heading-1,
.heading-1,
h1 {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(32, 38.4, 30, 36, 300, 300);
}
h2.heading-2,
.heading-2,
h2 {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(28, 33.6, 26, 31.2, 300, 300);
}
h3.heading-3,
.heading-3,
h3 {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(22, 26.4, 20, 24, 300, 300);
}
h4.heading-4,
.heading-4,
h4 {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(20, 24, 18, 21.6, 600, 600);
}
h5.heading-5,
.heading-5,
h5 {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(18, 25.2, 16, 19.2, 600, 700);
}
h6.heading-6,
.heading-6,
h6 {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(16, 22.4, 16, 19.2, 600, 700);
}
.body-large {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(18, 28.8, 18, 27, 400, 400);
}
.body-regular,
p {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(16, 25.6, 16, 23.2, 400, 400);
}
.body-small {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(14, 23.8, 14, 21, 400, 400);
}
.body-xs-small {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(12, 20.4, 12, 20.4, 400, 400);
}
.text-caption {
    @include theme-aware("color", "accent-primary-dark-1");
    @include Heading(14, 21, 14, 21, 600, 600);
}
.text-overline {
    text-transform: uppercase;
    @include Heading(12, 18, 12, 18, 700, 700);
}
a {
    @include theme-aware("color", "accent-primary-dark-1");
    &:hover {
        @include theme-aware("color", "accent-primary-dark-3");
    }
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.font-300 {
    font-weight: $font-weight-light !important;
}
.font-400 {
    font-weight: $font-weight-regular !important;
}
.font-500 {
    font-weight: $font-weight-medium !important;
}
.font-600 {
    font-weight: $font-weight-semibold !important;
}
.font-700 {
    font-weight: $font-weight-bold !important;
}
.font-size-60 {
    font-size: 60px;
}
.font-size-20 {
    font-size: 18px !important;
}
.font-size-16 {
    font-size: 16px !important;
}
.text-through {
    text-decoration: line-through;
}

table {
    p {
        margin: 0;
        padding: 0;
    }
}
