@import '../../scss/color-palette';

.welcome {
    margin-top: 55px;
    margin-bottom: 55px;
}
.label{
    color: $accent-primary-dark-1;
}
.input {
    min-height: 42px !important;
    border-color:$accent-primary-light-1 !important;
    background-color: $accent-primary-light-3 !important;
}