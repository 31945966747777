//COLORES
// colores principales segun la identidad corporativa del negocio:
//primary colors darks greens
$accent-primary-dark-1: #4f6f7c;
$accent-primary-dark-2: #7ba0b0;
$accent-primary-dark-3: #7aa9bc;
$accent-primary-dark-4: #5e9eb8;

$accent-primary: #97cadf; // primary color

//primary colors light greens
$accent-primary-light-0: #5e9eb8;
$accent-primary-light-1: #b7dae9;
$accent-primary-light-2: #cbe5ef;
$accent-primary-light-3: #e0f0f6;
$accent-primary-light-4: #eaf5fa;
///////////////////////////////////////////////
$accent-secondary-dark-1: #cabfb4;
$accent-secondary-dark-2: #b5a596;
$accent-secondary-dark-3: #957e68;
$accent-secondary-dark-4: #bf6056;
$accent-secondary-light-4: #fdf7f4;

$accent-secondary-light-1: #ead5c0;
$accent-secondary-light-2: #faefe9;
$accent-secondary-light-3: #f3e9e8;

$accent-secondary: #ead5c0; // secondary color

$accent-gray-light-1: #ced6e0;
$accent-gray-light-2: #dfe4ea;
$accent-gray-light-3: #f1f2f6;

$accent-gray-dark-1: #2f3542;
$accent-gray-dark-2: #57606f;
$accent-gray-dark-3: #747d8c;
$accent-gray-dark-4: #a4b0be;

/// legacy vars
$accent-color-1: $accent-secondary;
$accent-color-2: $accent-primary-dark-1;
$accent-color-3: $accent-primary-dark-2;
$accent-color-4: $accent-primary-dark-3;

$lightgrey: $accent-primary-light-3;
$border-light: darken($accent-primary-light-3, 5%);

$gray: $accent-secondary;
$elegant-color-dark: $accent-secondary;
$success: $accent-primary-light-2;

$promocolor: #5cc253;

$color-special-1: #5cc253;

$themes: (
  cucufate: (
    //primary colors darks greens
    accent-primary-dark-1: $accent-primary-dark-1,
    accent-primary-dark-2: $accent-primary-dark-2,
    accent-primary-dark-3: $accent-primary-dark-3,
    accent-primary: $accent-primary,
    // primary color
    accent-primary-light-1: $accent-primary-light-1,
    accent-primary-light-2: $accent-primary-light-2,
    accent-primary-light-3: $accent-primary-light-3,
    accent-secondary-dark-1: $accent-secondary-dark-1,
    accent-secondary-dark-2: $accent-secondary-dark-2,
    accent-secondary-dark-3: $accent-secondary-dark-3,
    accent-secondary-dark-4: $accent-secondary-dark-3,
    accent-secondary: $accent-secondary,
    // secondary color
    promocolor: $promocolor,
  ),
);
