@import "../../../scss/color-palette";

// img {
//     width: 100px;
//     cursor: pointer;
// }

figure {
  cursor: pointer;
}

.selected {
  border: 2px solid $accent-primary-light-1 !important;
}
