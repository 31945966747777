@import "../../scss/color-palette";
@import "../../scss/mixins";

.footer {
    background-color: $accent-primary-light-3;
    p {
        margin-bottom: 0;
    }
    &_soporte {
        display: flex;
        gap: 1.3rem;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        @media (min-width: 768px) {
            flex-direction: row;
            text-align: left;
            justify-content: space-between;
        }
    }
    &_column {
        @media (min-width: 821px) {
            margin-right: 2rem;
        }
        ul {
            padding: 0;
            li {
                list-style: none;
                margin: 1rem 0;
            }
        }
    }

    &_copy_brand {
        border-top: 1px solid $accent-primary-dark-2;
        &_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.5rem;
            padding-bottom: 1.5rem;
            @media (min-width: 768px) {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
    &_copyright {
        text-align: center;
        color: $accent-primary-dark-1;
        @media (min-width: 768px) {
            text-align: left;
        }
    }
    &_cards_brand {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        i {
            font-size: 2.5rem;
            color: $accent-primary-dark-1;
        }
    }
    .icons {
        display: flex;
        gap: 2rem;
        font-size: 2rem;
        color: $accent-primary-dark-1;
        justify-content: center;
    }
    .policies_label:hover {
        cursor: pointer;
    }
}
