@import '../../../scss/color-palette';

.button-display-block {
    display: block;
    width: 100%;
}
.button-display-block-mobile {
    @media (max-width: 480px) {
        display: block;
        width: 100%;
    }
}
.button-display-block-tablet {
    @media (min-width: 481px) and (max-width:991px) {
        display: block;
        width: 100%;
    }
}
@mixin buttonSize($size) {

    .button-#{$size}{
        font-weight: 600;
		border-radius: 0.2rem;
        transition: all .25s ease-out;

        @if $size == large {
            font-size: 18px;
            padding: 17px 30px;
            svg {
                height: 16px;
            }
        }

        @else if $size == medium {
            font-size: 16px;
            padding: 12px 15px;
            svg {
                height: 12px;
            }
        }
        @else if $size == small {
            font-size: 12px;
            padding: 8px 14px;
            svg {
                height: 10px;
            }
        }
        @else {
            font-size: 16px;
            padding: 12px 16px;
            svg {
                height: 12px;
            }
        }
		svg.carbon {
			height: 100%;
		}
        &.active {
            box-shadow: none;
        }
        &.button--disabled {
            color: $accent-secondary-dark-1;
            background-color: $accent-secondary-dark-3;
            cursor:not-allowed;
        
            &:hover {
                color: $accent-secondary-dark-1;
            background-color: $accent-secondary-dark-3;
                cursor: not-allowed !important;
                border:none !important;
            }
        }
    }
}

@mixin buttonTheme( $theme, $color ) {
    .button-#{$theme}-#{$color} {

		&:focus {
			outline: none;
		}
		// contained blue
		@if $theme == contained and $color == primary {
			border: none !important;
			color: white !important;
			background-color:$accent-primary-dark-3 !important;
			&:hover {
				background-color: $accent-primary-dark-1 !important;
			}
			&:focus {
				border: none !important;
				color: white !important;
				background-color:$accent-primary;
				outline: none;
			}
		}
		// outlined blue
		@if $theme == outlined and $color == primary {
			border: 1px solid $accent-primary;
			color: $accent-primary !important;
			background-color:white;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $accent-primary;
				box-shadow: 0 0  0 2 #008cff25;
				outline: none;
			}
		}
		// transparent blue
		@if $theme == transparent and $color == primary {
			border: none ;
			color: $accent-primary;
			background-color:transparent;
			padding: 0;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $accent-primary;
				box-shadow: 0 0  0 2 #008cff25;
				outline: none;
			}
		}
		
		// contained secondary
		@if $theme == contained and $color == secondary {
			color: white !important;
			background-color:$accent-secondary-dark-1 !important;
			border:none !important;
			&:hover {
				background-color: $accent-secondary-dark-1;
			}
			&:focus {
				color: white;
				background-color:$accent-secondary-dark-1;
				outline: none;
			}
		}
		// outlined secondary
		@if $theme == outlined and $color == secondary  {
			border: 1px solid $accent-secondary-dark-1 !important;
			color: $accent-secondary-dark-1 !important;
			background-color:white;
			&:hover {
				background-color: rgba(6, 69, 173, .1);
			}
			&:focus {
				outline: none;
			}
			&.button--disabled {
				border: none;
			}
		}
		// transparent secondary
		@if $theme == transparent and $color == secondary {
			border: none ;
			color: $accent-secondary ;
			background-color:transparent;
			padding: 0;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $accent-secondary;
				box-shadow: 0 0  0 2 #008cff25;
				outline: none;
			}
		}
		// contained red
		@if $theme == contained and $color == 'red' {
			border: none !important;
			color: white !important;
			background-color:#dc3545;
			&:hover {
				background-color: darken(#dc3545, .8);
			}
			&:focus {
				outline: none !important;
				color: white !important;
				background-color:#dc3545;
			}
		}
		// outlined red
		@if $theme == outlined and $color == 'red' {
			border: 1px solid #dc3545;
			color: #dc3545 !important;
			background-color:white;
			&:focus {
				background-color: #ffffff;
				border: 1px solid #dc3545;
				box-shadow: 0 0  0 2 #008cff25;
				outline: none;
			}
		}
		// transparent red
		@if $theme == transparent and $color == 'red' {
			border: none ;
			color: #dc3545;
			background-color:transparent;
			padding: 0;
			&:focus {
				background-color: #ffffff;
				border: 1px solid #dc3545;
				box-shadow: 0 0  0 2 #008cff25;
				outline: none;
			}
		}
	}
}

@include buttonSize(small);
@include buttonSize(medium);
@include buttonSize(large);

@include buttonTheme(contained,primary);
@include buttonTheme(outlined,primary);
@include buttonTheme('transparent',primary);

@include buttonTheme(contained,secondary);
@include buttonTheme(outlined,secondary);
@include buttonTheme('transparent',secondary);

@include buttonTheme(contained,'red');
@include buttonTheme(outlined,'red');
@include buttonTheme('transparent','red');

