
$spacing-01: 4; // px unit
$spacing-02: 8; // px unit
$spacing-03: 12; // px unit
$spacing-04: 16; // px unit
$spacing-05: 20; // px unit
$spacing-06: 24; // px unit
$spacing-07: 32; // px unit
$spacing-08: 40; // px unit
$spacing-09: 48; // px unit
$spacing-10: 56; // px unit
$spacing-11: 64; // px unit
$spacing-12: 80; // px unit


$units : $spacing-01, $spacing-02, $spacing-03, $spacing-04, $spacing-05, $spacing-06, $spacing-07, $spacing-08, $spacing-09, $spacing-10, $spacing-11, $spacing-12;

@mixin Spacing($spacing) {
    ////////////////// MARGIN
    .spacing-m-#{$spacing} {
        margin: $spacing+px !important;
        // return ej: .spacing-m-16 { margin: 1rem }
    }
    .spacing-mt-#{$spacing} {
        margin-top: $spacing+px !important;
        // return ej: .spacing-mt-16 { margin-top: 1rem }
    }
    .spacing-mr-#{$spacing} {
        margin-right: $spacing+px !important;
        // return ej: .spacing-mr-16 { margin-right: 1rem }
    }
    .spacing-mb-#{$spacing} {
        margin-bottom: $spacing+px !important;
        // return ej: .spacing-mb-16 { margin-bottom: 1rem }
    }
    .spacing-ml-#{$spacing} {
        margin-left: $spacing+px !important;
        // return ej: .spacing-ml-16 { margin-left: 1rem }
    }
    .spacing-mx-#{$spacing} {
        margin-right: $spacing+px !important;    
        margin-left: $spacing+px !important;
        // return ej: .spacing-mx-16 { margin-left: 1rem }
    }
    .spacing-my-#{$spacing} {
        margin-top: $spacing+px !important;    
        margin-bottom: $spacing+px !important;
        // return ej: .spacing-mx-16 { margin-left: 1rem }
    }
    ////////////////// PADDING
    .spacing-p-#{$spacing} {
        padding: $spacing+px !important;
        // return ej: .spacing-p-16 { padding: 1rem }
    }
    .spacing-pt-#{$spacing} {
        padding-top: $spacing+px !important;
        // return ej: .spacing-pt-16 { padding-top: 1rem }
    }
    .spacing-pr-#{$spacing} {
        padding-right: $spacing+px !important;
        // return ej: .spacing-pr-16 { padding-right: 1rem }
    }
    .spacing-pb-#{$spacing} {
        padding-bottom: $spacing+px !important;
        // return ej: .spacing-pb-16 { padding-bottom: 1rem }
    }
    .spacing-pl-#{$spacing} {
        padding-left: $spacing+px !important;
        // return ej: .spacing-pl-16 { padding-left: 1rem }
    }
    .spacing-px-#{$spacing} {
        padding-right: $spacing+px !important;
        padding-left: $spacing+px !important;
        // return ej: .spacing-px-16 { padding-left: 1rem }
    }
    .spacing-py-#{$spacing} {
        padding-top: $spacing+px !important;
        padding-bottom: $spacing+px !important;
        // return ej: .spacing-px-16 { padding-left: 1rem }
    }
}


// creating classNames: 
@each $unit in $units {
    @include Spacing($unit)
}